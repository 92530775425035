﻿@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/utilities";
@import "../mixins";
@import "../shared/spacing";
@import "../shared/typography";

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-move {
    cursor: move !important;
}

.cursor-grab {
    cursor: grab !important;
}

.cursor-default {
    cursor: default !important;
}

.input-number {
    @include input-number;
}


// table hover bg
.bg-hover {
    &-table {
        &:hover {
            background-color: $table-hover-bg;
        }
    }
}


// Check in / Check out

.locked-by-me {
    color: $success;
    cursor: pointer;
}

.locked-by-another {
    color: $danger;
    cursor: pointer;
}

.locked-disabled {
    color: $dark;
    cursor: auto;
}

.locked-in-office {
    color: $orange;
    cursor: pointer;
}

.specific-version {
    color: $orange;
}

// width, min-width and max-width utilities
@for $i from 0 through 50 {
    .width-#{$i} {
        width: #{$i}rem !important;
    }

    .min-width-#{$i} {
        min-width: #{$i}rem !important;
    }

    .max-width-#{$i} {
        max-width: #{$i}rem !important;
    }

    .height-#{$i} {
        height: #{$i}rem !important;
    }

    .min-height-#{$i} {
        min-height: #{$i}rem !important;
    }

    .max-height-#{$i} {
        max-height: #{$i}rem !important;
    }

    .force-width-#{$i} {
        min-width: #{$i}rem !important;
        width: #{$i}rem !important;
        max-width: #{$i}rem !important;
    }

    .force-height-#{$i} {
        min-height: #{$i}rem !important;
        height: #{$i}rem !important;
        max-height: #{$i}rem !important;
    }
}

// Pill (replace BS badge)
.pill {
    @include border-radius-20;
    @include typography-body-3;
    color: $mf-color-white;
    padding: spacer(3) spacer(10);

    &-success {
        background: $success;
    }

    &-primary {
        background: $primary;
    }

    * {
        text-transform: lowercase;
    }
}

// top utilities
@for $i from 1 through 32 {
    .top-#{$i} {
        top: #{$i}px !important;
    }
}

// Modern scrollbar class
.modern-scrollbar {
    @include modern-scrollbar;
}

// Pull-left and pull-right does not exist anymore in bootstrap 4+, so we're adding them here.
// TODO: remove usage of these two classes
.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.combo-box-with-label{
    display: flex;
    flex-direction: column;
    row-gap: spacer(8);
}

.combo-box-with-label-inline {
    display: flex;
    column-gap: spacer(8);
    align-items: center;

    .label {
        white-space: nowrap;
    }

    combo-box {
        width: auto;
    }
}

.settings-cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacer(16);
    padding-bottom: spacer(16);

    .card {
        &-header {
            display: flex;
            align-items: center;
            gap: spacer(8);
        }
    }
}
// ALERT
.alert-primary{
    &--alternate{
        background-color: $mf-color-blue-moment-5;
        border-color: $border-color-light;
    }
}

// text-disabled
.text-disabled{
    color: $mf-color-night-sky-20;
}


@import "bootstrap/scss/utilities/api";